import React from "react";
import "./EventDetails.scss";
import Img, { FluidObject } from "gatsby-image";
import { graphql, navigate } from "gatsby";
import { Gallery } from "../Gallery";
import { Layout } from "../Layout";
import { SEO } from "../SEO";

/**
 * EventDetails.tsx
 *
 * Component for the details of an event in Events and Activities page.
 *
 */

/**
 * EventDetailsProps
 * Component Props
 */
interface EventDetailProps {
  title: string;
  description: string;
  gallery: FluidObject[];
  data: any;
  location: any;
}

/**
 * EventDetails
 * Component Proper
 */
const EventDetails: React.FC<EventDetailProps> = ({ data, location }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const images = frontmatter.galleryPhotos.map((img: any, i: number) => ({
    src: img.childImageSharp.fluid,
    alt: `${frontmatter.title} - Gallery Photo #${i + 1}`,
  }));

  return (
    <Layout>
      <SEO title={`${frontmatter.title} | Events & Activities - ACSS UPLB`} />
      <main id="event-details">
        <header>
          <div id="back-navigation">
            <a
              onClick={() => {
                const initialLanding = location.key === "initial";
                if (initialLanding) navigate("/events-and-activities");
                else navigate(-1);
              }}
            >
              {/* Arrow Left (Back) */}
              <svg
                height="16"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 5H13"
                  stroke="#C1CDDA"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 1L1 5L5 9"
                  stroke="#C1CDDA"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Go back to Events &amp; Activities</span>
            </a>
          </div>
          <div id="hero-image">
            <Img
              fluid={frontmatter.heroImage.childImageSharp.fluid}
              alt={`-EventTitle- Hero Image`}
              style={{
                width: "100%",
                height: "100%",
              }}
              imgStyle={{
                objectPosition: "50% 37.5%",
              }}
            />
          </div>
          <h1 id="event-title">{frontmatter.title}</h1>
        </header>
        <section id="details-section">
          <h2 id="event-description-heading">Description</h2>
          <p
            id="event-description-text"
            dangerouslySetInnerHTML={{ __html: html }}
          ></p>
          <Gallery images={images} />
        </section>
      </main>
    </Layout>
  );
};

export default EventDetails;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galleryPhotos {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
